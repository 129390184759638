import { createAction, props } from '@ngrx/store';
import {BoxSummary} from '../../interfaces/box-summary.interface';

export enum BoxSummaryActionsTypes {
    getBoxSummary = '[BoxSummary] Get boxsummary',
    successGetBoxSummary = '[BoxSummary] Success get boxsummary',
    errorGetBoxSummary = '[BoxSummary] Error Get BoxSummary',

}

export const GetBoxSummary = createAction(BoxSummaryActionsTypes.getBoxSummary, props<{ filters: string }>());
export const successGetBoxSummary = createAction(BoxSummaryActionsTypes.successGetBoxSummary, props<{ boxsummary: Array<BoxSummary> }>());
export const errorGetBoxSummary = createAction(BoxSummaryActionsTypes.errorGetBoxSummary, props<{ payload: any }>());
